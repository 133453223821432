import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Loader from "./loader";

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-y: scroll;
`;
const StyledTable = styled.table`
  position: relative;
  display: grid;
  table-layout: fixed;
  border-collapse: collapse;

  min-width: 100%;
  grid-template-columns:
    minmax(30px, 1fr)
    minmax(200px, 5fr)
    minmax(50px, 2fr);
`;
const StyledThead = styled.thead`
  display: contents;
  position: sticky;
  top: 0;
`;
const StyledTh = styled.th`
  background: white;
  position: sticky;
  top: 0;
  padding: 15px;
  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: auto;
  /* white-space: nowrap; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  border-right: 1px solid #fff;
  /* grid-row: 1/3; */
  /* &:nth-child(3) {
    grid-column: 3/5;
    grid-row: 1/2;
  }
  &:nth-child(4) {
    grid-column: 3/4;
    grid-row: 2/3;
    border-top: 1px solid #fff;
    top: 56px;
  }
  &:nth-child(5) {
    grid-column: 4/5;
    grid-row: 2/3;
    border-top: 1px solid #fff;
    top: 56px;
  } */

  &:last-child {
    border-right: none;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #fff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  text-align: center;
  border-bottom: 1px solid #999;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  &:first-child {
    border-left: 2px solid #999;
  }
`;

const VacProfTable = () => {
  const [pods, setPods] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPods = async () => {
      setLoading(true);
      const response = await axios.get(
        "https://wp-api.proff86.ru/wp-json/wp/v2/vac_proff?per_page=100"
      );
      console.log("@@@", response.data);
      setLoading(false);
      setPods(response.data);
    };
    getPods();
  }, []);

  console.log({ pods });

  if (loading) {
    return <Loader />;
  }

  const renderedRows = pods.map((pod, index) => {
    return (
      <StyledTr key={pod.id}>
        <StyledTd>{index + 1}</StyledTd>
        <StyledTd>{pod.title.rendered}</StyledTd>
        <StyledTd>{pod.quantity}</StyledTd>
      </StyledTr>
    );
  });

  return (
    <Container>
      <h1 className="text-xl mb-4 text-center tracking-wider text-gray-700">
        Вакантные места на профессиональное обучение
      </h1>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>№</StyledTh>
            <StyledTh>Профессия</StyledTh>
            <StyledTh>Количество</StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedRows}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default React.memo(VacProfTable);
