import React from "react";

const Loader = () => {
  return (
    <div className="p-4 ">
      <h1 className="text-blue-700 text-2xl">
        Данные загружаются. Подождите...{" "}
      </h1>
    </div>
  );
};

export default Loader;
