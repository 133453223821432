import React, { useState } from 'react';

import Layout from '../components/layout';
import VacanciesTable from '../components/vacanciesTable';
import SEO from '../components/seo';
import Modal from '../components/modal';
import VacProfTable from '../components/vacProffTable';
import VacDopTable from '../components/vacDopTable';

function Vacancies() {
  const [modalOpened, setModalOpened] = useState(false);
  const [dopModalOpened, setDopModalOpened] = useState(false);

  const handleModalClose = () => {
    setModalOpened(false);
    setDopModalOpened(false);
  };

  return (
    <Layout>
      <SEO
        keywords={[
          `Вакансии`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Образование'
      />

      <section className='flex flex-col items-center '>
        <h1 className=' text-center text-2xl uppercase text-green-800 font-bold mb-4'>
          ВАКАНТНЫЕ МЕСТА ДЛЯ ПРИЁМА (ПЕРЕВОДА)
        </h1>
        <VacanciesTable />
        {/* <p className='p-4 text-xl'>
          Приём обучающихся в ЧОУ ДПО «Профф» ведётся в соответствии с
          заключаемыми договорами об оказании платных образовательных услуг без
          ограничений. Квоты и контрольные цифры не установлены.
        </p> */}
        <p className='p-4 text-xl text-center'>
          Вакантные места для приема (перевода) на обучение по программам
          дополнительного профессионального образования и основным программам
          профессионального обучения за счет средств физических и (или)
          юридических лиц не предусмотрены.
        </p>

        <p className='p-4 text-xl text-center'>
          Прием на обучение проводится в течение всего календарного года, без
          вступительных испытаний.
        </p>
      </section>
      <section>
        <div
          className='py-4 px-8 m-4 rounded bg-white hover:bg-gray-300  cursor-pointer'
          onClick={() => setModalOpened(true)}
        >
          <h1 className='text-xl text-center uppercase tracking-wider text-green-700 hover:text-gray-700'>
            Вакантные места на ПРОФЕССИОНАЛЬНОЕ ОБУЧЕНИЕ
          </h1>
        </div>
      </section>
      <section>
        <div
          className='py-4 px-8 m-4 rounded bg-white hover:bg-gray-300  cursor-pointer'
          onClick={() => setDopModalOpened(true)}
        >
          <h1 className='text-xl text-center uppercase tracking-wider text-green-700 hover:text-gray-700'>
            Вакантные места на дополнительное проффесиональное ОБУЧЕНИЕ
          </h1>
        </div>
      </section>

      {modalOpened && (
        <Modal onClose={handleModalClose}>
          <VacProfTable />
        </Modal>
      )}
      {dopModalOpened && (
        <Modal onClose={handleModalClose}>
          <VacDopTable />
        </Modal>
      )}
    </Layout>
  );
}

export default Vacancies;
