import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  /* background: #999; */
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-x: scroll;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
  display: grid;
  /* border-collapse: collapse; */
  min-width: 100%;
  grid-template-columns:
    minmax(200px, 2fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(80px, 1fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  padding: 15px;
  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: ;
  /* white-space: nowrap; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  border-right: 1px solid #fff;
  &:first-child {
    grid-row: 1/3;
  }
  &:nth-child(2) {
    /* grid-column-start: span 4; */
    border-bottom: 1px solid #fff;
    grid-column: 2/6;
    grid-row: 1/2;
  }
  &:last-child {
    border-right: none;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  /* border: 1px solid #333; */
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #999;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 2px solid #999;
  }
`;

const data = [
  'Повышение квалификации (плановое значение)',
  'Профессиональная переподготовка (плановое значение)',
  'Итого:',
];

const VacTable = () => {
  const renderedPods = data.map((pod) => {
    return (
      <StyledTr key={pod}>
        <StyledTd className='text-lg font-bold tracking-wider'>{pod}</StyledTd>
        <StyledTd>0</StyledTd>
        <StyledTd>0</StyledTd>
        <StyledTd>0</StyledTd>
        <StyledTd>100%</StyledTd>
      </StyledTr>
    );
  });

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>Класс / Группа</StyledTh>
            <StyledTh>КОЛИЧЕСТВО ВАКАНТНЫХ МЕСТ ДЛЯ ПРИЕМА(ПЕРЕВОДА)</StyledTh>
            <StyledTh>
              ЗА СЧЁТ БЮДЖЕТНЫХ АССИГНОВАНИЙ ФЕДЕРАЛЬНОГО БЮДЖЕТА
            </StyledTh>
            <StyledTh>
              ЗА СЧЁТ БЮДЖЕТНЫХ АССИГНОВАНИЙ БЮДЖЕТОВ СУБЪЕКТА РОССИЙСКОЙ
              ФЕДЕРАЦИИ
            </StyledTh>
            <StyledTh>ЗА СЧЁТ БЮДЖЕТНЫХ АССИГНОВАНИЙ МЕСТНЫХ БЮДЖЕТОВ</StyledTh>
            <StyledTh>
              ЗА СЧЁТ СРЕДСТВ ФИЗИЧЕСКИХ И (ИЛИ) ЮРИДИЧЕСКИХ ЛИЦ
            </StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default VacTable;
